import(/* webpackMode: "eager" */ "/app/asset/icon/logo/playsee_logo_new.svg");
;
import(/* webpackMode: "eager" */ "/app/component/block/header/header.module.css");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(termsPage)/terms.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/component/block/language-selector/language-selector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/component/page/terms/previous-terms.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
